<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <template slot="list" slot-scope="data">
      <b-table
        id="report"
        :fields="table.fields"
        :items="items"
        :filter="filter"
        class="edge"
        :sort-by="data.sort.by"
        :sort-desc="data.sort.desc"
        @sort-changed="$emit('sortChange', $event)"
        no-local-sorting
        responsive
      >
        <template slot="name" slot-scope="data" v-if="data.item._rowVariant !== 'delimiter'">
          <span
            :class="{ 'pl-10': data.item.parent }"
            @click="expand(data.item)"
            :style="{ cursor: data.item.parent ? 'unset' : 'pointer' }"
          >
            <fa v-if="!data.item.parent && data.item.id" :icon="'angle-' + (data.item._expanded ? 'up' : 'down')" />
            <template v-else>
              <div v-if="data.item.n" class="text-secondary user-n">{{ data.item.n }}.</div>

              <template v-if="data.item.employee">
                <!--Индикатор-->
                <span class="employee-indicator" :class="dotColor(data.item)"></span>

                <responsible
                  v-if="data.item.employee"
                  :photo="data.item.photo"
                  :id="'name-row_' + data.index"
                  class="mr-5"
                />

                <popover :target="'name-row_' + data.index">
                  <div v-if="data.item.last_time">{{ statusText(data.item) }}:</div>

                  <div v-if="data.item.last_project">
                    <b>Проект: </b>
                    <b-link
                      :to="`/projects/list?filters[projects][id]=${data.item.last_project.id}&filters[projects][preset]=allActiveAndArchive`"
                      target="_blank"
                    >
                      {{ data.item.last_project.name }}
                    </b-link>
                  </div>
                  <div v-if="data.item.last_task">
                    <b>Задача: </b>
                    <b-link :to="`/tasks/list?filters[tasks][id]=${data.item.last_task.id}`" target="_blank">
                      {{ data.item.last_task.name }}
                    </b-link>
                  </div>
                </popover>
              </template>
            </template>

            <b-link v-if="data.item.employee" :to="`/employees/${data.item.employee_id}`" target="_blank">{{
              data.value
            }}</b-link>
            <span v-else class="ml-5">{{ data.value }}</span>
          </span>
        </template>

        <template slot="rate" slot-scope="data">
          <b-link :id="'rate-row_' + data.index" target="_blank" :to="linkToReportClients(data.item, 'all')">
            {{ data.value | formatNumber }} &#8381;
          </b-link>
          <popover :target="'rate-row_' + data.index">
            {{ data.item.hours_total | formatDuration(undefined, undefined, 'hours') }} *
            {{ data.item.rate_premium | formatNumber }} &#8381; = {{ data.value | formatNumber }} &#8381;
          </popover>
        </template>

        <template slot="hours_total" slot-scope="data">
          <span :id="'hours_total-row_' + data.index">
            <b-link target="_blank" :to="linkToReportClients(data.item, 'all')">
              {{ data.value | formatDuration(undefined, undefined, 'hours') }}
            </b-link>
          </span>
        </template>

        <template slot="hours_inside" slot-scope="data">
          <span :id="'hours_inside-row_' + data.index">
            <b-link target="_blank" :to="linkToReportClients(data.item, 'inside_not_premium')">
              {{ data.value | formatDuration(undefined, undefined, 'hours') }} ({{
                data.item.hours_inside_percent | formatNumber
              }}%)
            </b-link>
          </span>
        </template>

        <template slot="hours_premium" slot-scope="data">
          <span :id="'hours_premium-row_' + data.index">
            <b-link target="_blank" :to="linkToReportClients(data.item, 'inside_premium')">
              {{ data.value | formatDuration(undefined, undefined, 'hours') }} ({{
                data.item.hours_premium_percent | formatNumber
              }}%)
            </b-link>
          </span>
        </template>

        <template slot="hours_projects" slot-scope="data">
          <span :id="'hours_projects-row_' + data.index">
            <b-link target="_blank" :to="linkToReportClients(data.item, 'outside')">
              {{ data.value | formatDuration(undefined, undefined, 'hours') }} ({{
                data.item.hours_projects_percent | formatNumber
              }}%)
            </b-link>
          </span>
        </template>

        <template slot="worth" slot-scope="data"> {{ data.value | formatNumber }} &#8381; </template>

        <template slot="bonus_managers" slot-scope="data">
          <b-link
            :to="linkToProjects(data.item, 'project_manager_id')"
            :id="'bonus_managers-row_' + data.index"
            target="_blank"
          >
            {{ data.value | formatNumber }} &#8381;
          </b-link>
        </template>

        <template slot="bonus_acts" slot-scope="data">
          <b-link
            :to="linkToProjects(data.item, 'project_manager_id')"
            :id="'bonus_acts-row_' + data.index"
            target="_blank"
          >
            {{ data.value | formatNumber }} &#8381;
          </b-link>
        </template>

        <template slot="bonus_managers_future" slot-scope="data">
          <b-link
            :to="linkToProjects(data.item, 'project_manager_id', { preset: 'allInWork', project_end: null })"
            :id="'bonus_managers_future-row_' + data.index"
            target="_blank"
          >
            {{ data.value | formatNumber }} &#8381;
          </b-link>
        </template>

        <template slot="bonus_project_member" slot-scope="data">
          <b-link
            :to="linkToTasks(data.item, false, { bonus_type: 'outside', billable: 'Y' })"
            :id="'bonus_project_member-row_' + data.index"
            target="_blank"
          >
            {{ data.value | formatNumber }} &#8381;
          </b-link>
          <popover v-if="data.item.employee" :target="'bonus_project_member-row_' + data.index">
            {{ data.item.hours_closed | formatDuration(undefined, undefined, 'hours') }} *
            {{ data.item.hourly_premium | formatNumber }} &#8381; *
            <span :class="{ red: data.item.coeff_project_member <= 1, green: data.item.coeff_project_member > 1 }">
              {{ data.item.coeff_project_member | formatNumber('0,0.[00]') }}
            </span>
            = {{ data.value | formatNumber }} &#8381;
          </popover>
        </template>

        <template slot="closed_budget" slot-scope="data">
          <b-link target="_blank" :to="linkToReportCost(data.item)"> {{ data.value | formatNumber }} &#8381; </b-link>
        </template>

        <template slot="unload_acts" slot-scope="data">
          <b-link target="_blank" :to="linkToReportCost(data.item)"> {{ data.value | formatNumber }} &#8381; </b-link>
        </template>

        <template slot="bonus_task_member" slot-scope="data">
          <b-link
            :id="'bonus_task_member-row_' + data.index"
            :to="linkToTasks(data.item, false, { bonus_type: 'inside', billable: 'Y' })"
            target="_blank"
          >
            {{ data.value | formatNumber }} &#8381;
          </b-link>
          <popover v-if="data.item.employee" :target="'bonus_task_member-row_' + data.index">
            {{ data.item.task_hours_closed | formatDuration(undefined, undefined, 'hours') }} *
            {{ data.item.task_hourly_premium | formatNumber }} &#8381; *
            <span :class="{ red: data.item.coeff_task_member <= 1, green: data.item.coeff_task_member > 1 }">
              {{ data.item.coeff_task_member | formatNumber('0,0.[00]') }}
            </span>
            = {{ data.item.bonus_task_member | formatNumber }} &#8381;
          </popover>
        </template>

        <template slot="future_bonus_project_member" slot-scope="data">
          <b-link
            :id="'future_bonus_project_member-row_' + data.index"
            :to="linkToTasks(data.item, true, { bonus_type: 'outside' })"
            target="_blank"
            >{{ data.value | formatNumber }} &#8381;
          </b-link>
          <popover v-if="data.item.employee" :target="'future_bonus_project_member-row_' + data.index">
            {{ data.item.future_total_hours | formatDuration(undefined, undefined, 'hours') }} *
            {{ data.item.future_hourly_premium | formatNumber }} &#8381; *
            <span :class="{ red: data.item.future_coeff <= 1, green: data.item.future_coeff > 1 }">
              {{ data.item.future_coeff | formatNumber('0,0.[00]') }}
            </span>
            = {{ data.value | formatNumber }} &#8381;
          </popover>
        </template>

        <template slot="future_bonus_task_member" slot-scope="data">
          <b-link
            :id="'future_bonus_task_member-row_' + data.index"
            :to="linkToTasks(data.item, true, { bonus_type: 'inside' })"
            target="_blank"
          >
            {{ data.value | formatNumber }} &#8381;
          </b-link>
          <popover v-if="data.item.employee" :target="'future_bonus_task_member-row_' + data.index">
            {{ data.item.future_task_total_hours | formatDuration(undefined, undefined, 'hours') }} *
            {{ data.item.future_task_hourly_premium | formatNumber }} &#8381; *
            <span :class="{ red: data.item.future_task_coeff <= 1, green: data.item.future_task_coeff > 1 }">
              {{ data.item.future_task_coeff | formatNumber('0,0.[00]') }}
            </span>
            = {{ data.item.future_bonus_task_member | formatNumber }} &#8381;
          </popover>
        </template>

        <template slot="worth_projects" slot-scope="data"> {{ data.value | formatNumber }} &#8381; </template>

        <template slot="worth_inside" slot-scope="data"> {{ data.value | formatNumber }} &#8381; </template>

        <template slot="worth_premium" slot-scope="data"> {{ data.value | formatNumber }} &#8381; </template>

        <template slot="salary" slot-scope="data">
          {{ (data.value + data.item.bonus_managers + data.item.bonus_acts) | formatNumber }} &#8381;
        </template>

        <!--Header-->

        <th-extended slot="HEAD_bonus_project_member" slot-scope="data" :data="data">
          <template slot="popover"> Премия по закрытым <b>клиентским</b> задачам в этом периоде </template>
        </th-extended>

        <th-extended slot="HEAD_bonus_task_member" slot-scope="data" :data="data">
          <template slot="popover"> Премия по закрытым <b>внутренним</b> задачам в этом периоде </template>
        </th-extended>

        <th-extended slot="HEAD_bonus_managers" slot-scope="data" :data="data">
          <template slot="popover"> Премия <b>менеджера</b> по закрытым <b>проектам</b> </template>
        </th-extended>

        <th-extended slot="HEAD_bonus_acts" slot-scope="data" :data="data">
          <template slot="popover"> Премия <b>исполнителя</b> по закрытым <b>актам</b> </template>
        </th-extended>

        <th-extended slot="HEAD_bonus_task_member" slot-scope="data" :data="data">
          <template slot="popover"> Премия по закрытым <b>внутренним</b> задачам в этом периоде </template>
        </th-extended>

        <th-extended slot="HEAD_future_bonus_project_member" slot-scope="data" :data="data">
          <template slot="popover"> Премиальный фонд по незакрытым <b>клиентским</b> задачам </template>
        </th-extended>

        <th-extended slot="HEAD_future_bonus_task_member" slot-scope="data" :data="data">
          <template slot="popover"> Премиальный фонд по незакрытым <b>внутренним</b> задачам </template>
        </th-extended>

        <th-extended slot="HEAD_closed_budget" slot-scope="data" :data="data">
          <template slot="popover"> Закрыто проектов </template>
        </th-extended>

        <th-extended slot="HEAD_hours_total" slot-scope="data" :data="data" :fields="table.fields"> </th-extended>

        <th-extended slot="HEAD_worth" slot-scope="data" :data="data" :fields="table.fields"> </th-extended>

        <th-extended slot="HEAD_salary" slot-scope="data" :data="data" :fields="table.fields"> </th-extended>
      </b-table>
    </template>
  </list>
</template>

<script>
  import qs from 'qs'
  import forEach from 'lodash/forEach'
  import omit from 'lodash/omit'
  import cloneDeep from 'lodash/cloneDeep'
  import moment from 'moment'
  import { getUsersDropdown } from '../../../assets/js/common'

  import entityMixins from '../../../mixins/entity'
  import rightsMixins from '../../../mixins/rights'

  import List from '../../../components/List'
  import Popover from '../../../components/PopoverAlive'
  import ThExtended from '../../../components/ThExtended'
  import Responsible from '../../../components/Responsible'

  export default {
    name: 'report',
    components: {
      List,
      Popover,
      Responsible,
      ThExtended,
    },
    mixins: [entityMixins, rightsMixins],
    data() {
      const isAdmin = this.$store.state.auth.user.positions.indexOf('admin') >= 0

      return {
        filter(item) {
          if (!item.parent || (item.parent && item.parent._expanded)) {
            return true
          }
        },
        table: {
          fields: [
            {
              key: 'name',
              label: 'Отделы',
              sortable: true,
            },
            {
              key: 'delimiter_10',
              label: '&nbsp;',
              class: 'delimiter mid-delimiter',
            },
            {
              key: 'hours_total',
              label: 'Часы, итого',
              class: 'text-center',
              expander: {
                include: 3,
              },
            },
            {
              key: 'hours_inside',
              label: 'Окладные',
              class: 'text-center',
            },
            {
              key: 'hours_premium',
              label: 'Премиальные',
              class: 'text-center',
            },
            {
              key: 'hours_projects',
              label: 'Проектные',
              class: 'text-center',
            },
            {
              key: 'delimiter_20',
              label: '&nbsp;',
              class: 'delimiter mid-delimiter',
            },

            {
              key: 'future_bonus_task_member',
              label: 'ПЗ (в работе)',
              class: 'text-center',
            },
            {
              key: 'future_bonus_project_member',
              label: 'ПП (в работе)',
              class: 'text-center',
            },
            {
              key: 'bonus_managers_future',
              label: 'БМ (в работе)',
              class: 'text-center',
            },
            {
              key: 'delimiter_30',
              label: '&nbsp;',
              class: 'delimiter mid-delimiter',
            },

            {
              key: 'salary',
              label: 'Премия, итого',
              class: 'text-center',
              expander: {
                include: 4,
              },
            },

            {
              key: 'bonus_task_member',
              label: 'ПЗ (факт)',
              class: 'text-center',
            },
            {
              key: 'bonus_project_member',
              label: 'ПП (факт)',
              class: 'text-center',
            },
            // {
            //   key: 'bonus_managers',
            //   label: 'БМ (факт)',
            //   class: 'text-center',
            // },
            {
              key: 'bonus_acts',
              label: 'БА (факт)',
              class: 'text-center',
            },
            {
              key: 'delimiter_40',
              label: '&nbsp;',
              class: 'delimiter mid-delimiter',
            },

            {
              key: 'worth',
              label: 'Себ., итого',
              class: 'text-center',
              roles: ['admin'],
              expander: {
                include: 3,
                closed: true,
              },
            },
            {
              key: 'worth_inside',
              label: 'Себ. внутренняя',
              class: 'text-center',
              roles: ['admin'],
            },
            {
              key: 'worth_premium',
              label: 'Себ. премиальная',
              class: 'text-center',
              roles: ['admin'],
            },
            {
              key: 'worth_projects',
              label: 'Себ. проектная',
              class: 'text-center',
              roles: ['admin'],
            },
            {
              key: 'delimiter_50',
              label: '&nbsp;',
              class: 'delimiter mid-delimiter',
            },
            {
              key: 'unload_acts',
              label: 'Отгружено актов',
              class: 'text-center',
            },
            {
              key: 'closed_budget',
              label: 'Сумма отгрузки',
              class: 'text-center',
            },
          ],
        },
        filters: [
          {
            name: 'date',
            type: 'date',
            range: true,
            legend: 'Период',
          },
          {
            name: 'users',
            label: 'Сотрудники',
            type: 'select',
            multiple: true,
            items: [],
            width: 2,
            roles: ['admin'],
          },
        ],
      }
    },
    computed: {},
    methods: {
      dotColor(item) {
        let date = moment(item.last_time)
        let diff = moment().diff(date, 'minutes')

        if (diff < 5) {
          return { ok: true }
        } else if (diff >= 5 && diff < 2880) {
          return { warning: true }
        } else {
          return { danger: true }
        }
      },

      statusText(item) {
        let date = moment(item.last_time)
        let color = this.dotColor(item)

        if (color.ok) {
          return 'Сейчас работает над'
        } else {
          return `Работал ${date.fromNow()} над`
        }
      },

      linkToReportCost(item) {
        let link = '/reports/projects_movement?',
          params = {}

        if (item.department) {
          params.manager_id = 'department_' + item.id
        }

        if (item.employee) {
          params.manager_id = 'user_' + item.id
        }

        // Фильтр даты
        let date = this.module.state.filters.date
        if (date) {
          params.date = date
        }

        return (
          link +
          qs.stringify({
            filters: {
              'reports/projects_movement': params,
            },
          })
        )
      },

      linkToReportClients(item, type) {
        let link = '/reports/clients?',
          params = {
            visibility: type,
          }

        if (item.department) {
          params.users = 'department_' + item.id
        }

        if (item.employee) {
          params.users = 'user_' + item.id
        }

        // Фильтр даты
        let date = this.module.state.filters.date
        if (date) {
          params.date = date
        }

        return (
          link +
          qs.stringify({
            filters: {
              'reports/clients': params,
            },
          })
        )
      },

      linkToProjects(item, type, extend) {
        let link = '/projects?',
          params = {
            preset: 'allActiveAndArchive',
          }

        if (type) {
          if (item.department) {
            params[type] = 'department_' + item.id
          }

          if (item.employee) {
            params[type] = 'user_' + item.id
          }

          if (type === 'followers') {
            params[type] = [params[type]]
          }
        }

        // Фильтр даты
        let date = this.module.state.filters.date
        if (date) {
          params.project_end = date
        }

        if (extend) {
          Object.assign(params, extend)
        }

        return (
          link +
          qs.stringify({
            filters: {
              projects: params,
            },
          })
        )
      },

      linkToTasks(item, future = false, extend = {}) {
        let link = '/tasks?'
        let params = {}

        params.preset = future ? 'allOpened' : 'allClosed'

        if (item.department) {
          params.followers = 'department_' + item.id
        }

        if (item.employee) {
          params.followers = 'user_' + item.id
        }

        // Фильтр даты
        let date = this.module.state.filters.date
        if (date) {
          if (!future) {
            params.close_date = date
          }
        }

        params = { ...params, ...extend }

        return (
          link +
          qs.stringify({
            filters: {
              tasks: params,
            },
          })
        )
      },

      expand(item) {
        this.module.commit('updateItem', {
          source: item,
          merge: true,
          data: {
            _expanded: !item._expanded,
          },
        })
      },

      prepareData(data) {
        let preparedItems = [],
          total = omit(data, 'items')

        total._rowVariant = 'bold'

        preparedItems.push(total)
        addDelimiter()

        let n = 1

        forEach(data.items, item => {
          let department = omit(item, 'items')

          Object.assign(department, {
            department: true,
            _rowVariant: 'bold',
            _expanded: true,
          })

          preparedItems.push(department)

          forEach(item.items, employee => {
            employee.n = n
            employee.employee = true
            employee.parent = department
            preparedItems.push(employee)
            n++
          })

          addDelimiter()
        })

        function addDelimiter() {
          preparedItems.push({
            _rowVariant: 'delimiter mid-delimiter',
          })
        }

        return preparedItems
      },

      resultHandler(result) {
        result.items = this.prepareData(result.data)
      },
    },
    beforeRouteEnter(to, from, next) {
      getUsersDropdown().then(items => {
        next(vm => {
          vm.filters
            .filter(filter => {
              return ['users'].indexOf(filter.name) !== -1
            })
            .forEach(f => (f.items = cloneDeep(items)))
        })
      })
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    .filters {
      margin-bottom: 15px !important;
    }

    table {
      tr {
        th,
        td {
          &:first-child {
            padding-left: 20px;
          }
        }

        &:first-child {
          th {
            &:first-child {
              width: 300px;
            }
          }
        }
      }

      .table-bold {
        font-weight: 600;
      }
    }
  }

  .user-n {
    min-width: 20px;
    width: 20px;
    display: inline-block;
  }

  .employee-indicator {
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 50%;

    &.danger {
      background: rgba(0, 0, 0, 0.1);
    }

    &.warning {
      background: #e4b248;
    }

    &.ok {
      background: rgba(0, 153, 0, 0.6);
    }
  }
</style>
